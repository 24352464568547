import snake from "./images/snake.png";
import memory_game from "./images/memory_game.png";
import whack_a_mole from "./images/whack_a_mole.png";
import connect4 from "./images/connect4.png";
import space_invaders from "./images/space_invaders.png";
import frogger from "./images/frogger.png";
import battlecity from "./images/battlecity.png";
import tetris from "./images/tetris.png";
import yummiefood from "./images/yummiefood.png";
import a2021frontend from "./images/2021frontend.png";
import locs2see from "./images/locs2see.png";
import dadata_api_project from "./images/dadata_api_project.png";
import leaflet_test from "./images/leaflet_test.png";
import jokeapi from "./images/jokeapi.png";
import waydoc_print from "./images/waydoc_print.png";
import pokemon_db from "./images/pokemon_db.png";

export const projects = [
    {
        id: 16,
        title: "Pokemon DB (Typescript + ReactJS)",
        image: pokemon_db,
        description: "User interface home-project for PokeAPI (pokeapi.co)",
        link: "https://www.tomasian.ru/pokemon_db",
        github: "https://github.com/tamosin/ts_react_pokemon",
        filter: "from_scratch",
    },
    {
        id: 15,
        title: "Waydoc printing form (ReactJS)",
        image: waydoc_print,
        description: "Print XLS-like htm forms in browser.",
        link: "https://www.tomasian.ru/waydoc_print",
        github: "https://github.com/tamosin/waydoc_print",
        filter: "from_scratch",
    },
    {
        id: 14,
        title: "Joke API (ReactJS, API)",
        image: jokeapi,
        description: "Jokes from https://sv443.net/jokeapi/v2/.",
        link: "https://www.tomasian.ru/jokeapi",
        github: "https://github.com/tamosin/jokeapi",
        filter: "from_scratch",
    },
    {
        id: 13,
        title: "Leaflet (ReactJS, Leaflet)",
        image: leaflet_test,
        description: "Using leaflet in react test project. Pet-project, made from scratch, only frontend. .",
        link: "https://www.tomasian.ru/leaflet_test",
        github: "https://github.com/tamosin/leaflet_test",
        filter: "from_scratch",
    },
    {
        id: 12,
        title: "Locs to see (ReactJS)",
        image: locs2see,
        description: "Social network for collecting and visiting interesting places all over the world. Pet-project, made from scratch, only frontend.",
        link: "https://www.tomasian.ru/locstosee",
        github: "https://github.com/tamosin/placestosee",
        filter: "from_scratch",
    },
    {
        id: 11,
        title: "DADATA API test project (ReactJS)",
        image: dadata_api_project,
        description: "Trying to use dadata Russian address autocomplete API.",
        link: "https://tomasian.ru/dadata_api_project/",
        github: "https://github.com/tamosin/dadata_api_project",
        filter: "from_scratch",
    },

    {
        id: 10,
        title: "Memory Game on JS",
        image: memory_game,
        description: "Modified tutorial project by Ania Kubow.",
        link: "https://www.tomasian.ru/memorygame",
        github: "https://google.ru",
        original: "https://www.youtube.com/watch?v=tjyDOHzKN0w",
        filter: "tutorial",
    },
    {
        id: 9,
        title: "Whack-A-Mole game on JS",
        image: whack_a_mole,
        description: "Modified tutorial project by Ania Kubow.",
        link: "https://tomasian.ru/whackamole/",
        github: "https://google.ru",
        original: "https://www.youtube.com/watch?v=rJU3tHLgb_c",
        filter: "tutorial",
    },
    {
        id: 8,
        title: "Connect Four game on JS",
        image: connect4,
        description: "Modified tutorial project by Ania Kubow.",
        link: "https://tomasian.ru/connect4/",
        github: "https://google.ru",
        original: "https://www.youtube.com/watch?v=aroYjgQH8Tw",
        filter: "tutorial",
    },
    {
        id: 7,
        title: "Snake game on JS",
        image: snake,
        description: "Modified tutorial project by Ania Kubow.",
        link: "https://www.tomasian.ru/snake",
        github: "https://google.ru",
        original: "https://www.youtube.com/watch?v=rui2tRRVtc0",
        filter: "tutorial",
    },
    {
        id: 6,
        title: "Space Invaders game on JS",
        image: space_invaders,
        description: "Modified tutorial project by Ania Kubow.",
        link: "https://www.tomasian.ru/space_invaders",
        github: "https://google.ru",
        original: "https://www.youtube.com/watch?v=3Nz4Yp7Y_uA",
        filter: "tutorial",
    },
    {
        id: 5,
        title: "Frogger game on JS",
        image: frogger,
        description: "Modified tutorial project by Ania Kubow.",
        link: "https://www.tomasian.ru/frogger",
        github: "https://google.ru",
        original: "https://www.youtube.com/watch?v=L-FWBhjt6CU",
        filter: "tutorial",
    },
    {
        id: 4,
        title: "Battle City game on JS",
        image: battlecity,
        description: "Battle City game made from scratch on JS. Original game released by NAMCO on Famicom Entertaiment System.",
        link: "https://www.tomasian.ru/battlecity",
        github: "https://google.ru",
        filter: "from_scratch",
    },
    {
        id: 3,
        title: "Tetris game on JS",
        image: tetris,
        description: "Tetris game made from scratch on JS. Original game by Alexei Pajitnov.",
        link: "https://www.tomasian.ru/tetris",
        github: "https://google.ru",
        filter: "from_scratch",
    },
    {
        id: 2,
        title: "Yammie Food site (HTML, CSS)",
        image: yummiefood,
        description: "Frontend crash course from youtube-channel DesignCourse.",
        link: "https://www.tomasian.ru/yummiefood",
        github: "https://github.com/tamosin/2020frontend",
        original: "https://www.youtube.com/watch?v=QA0XpGhiz5w",
        filter: "tutorial",
    },
    {
        id: 1,
        title: "Remember That site (HTML, CSS)",
        image: a2021frontend,
        description: "Frontend crash course from youtube-channel DesignCourse.",
        link: "https://www.tomasian.ru/2021frontend",
        github: "https://github.com/tamosin/2021frontend",
        original: "https://www.youtube.com/watch?v=D-h8L5hgW-w",
        filter: "tutorial",
    },
];

export const filters = [
    { id: 1, name: "from_scratch", title: "Made from scratch" },
    { id: 2, name: "tutorial", title: "Tutorials" },
    { id: 3, name: "All", title: "All" },
];
