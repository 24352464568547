function Project({
  title,
  image,
  description,
  link,
  github,
  original,
  active,
}) {  
  return (
    <article className="project-article">
      <h4>{title}</h4>
      <img className="thumbnail" src={image} alt={title} />
      <p className="project-desription">{description}</p>
      <a className="project-btn" href={link}>
        To project
      </a>
      <a className="project-a" href={github}>
        Github
      </a>

      {original && (
        <a className="project-a" href={original}>
          Original tutorial
        </a>
      )}
    </article>
  );
}

export default Project;
