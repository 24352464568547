import React, { useState } from "react";
import myphoto from "./images/mesquare.png";
import { projects, filters } from "./data";
import Project from "./components/Project";
import "./App.css";

function App() {
  const [currentFilter, setCurrentFilter] = useState("from_scratch");

  const filterClickHandle = (e) => {
    if (e.target.id) {
      setCurrentFilter(e.target.id);
    }
  };

  return (
    <>
      <header className="header">
        <h1>tomasian</h1>
        <a className="contact-me" href="#footer">
          Contact me
        </a>
      </header>
      <section className="about-me-section">
        <img className="my-photo" src={myphoto} alt="My photo" />
        <div className="about-me-bio">
          <h4>Hello!</h4>
          <p>
            My name is Alexander and I am a junior WEB-developer (html, css, js,
            ReactJS and more). I specialize on using ReactJS to make fast,
            modern, responsive interfaces (at least I think so :) ). On this
            page you can see portfolio of my pet- and tutorial projects.
          </p>
          <p>
            I recommend you to complete courses by <a href="https://www.johnsmilga.com/"> John Smilga </a> (I didnt place
            here results of his tutorial projects, but it help me very mush to
            understand ReactJS).{" "}
          </p>
        </div>
      </section>
      <section className="filter-projects">
        <div className="filter-container">
          <h4 className="filter-title">Filter projects: </h4>
          {filters.map((item) => {
            return (
              <a
                key={item.id}
                className={
                  "filter-btn" +
                  (item.name === currentFilter ? " filter-btn-active" : "")
                }
                id={item.name}
                onClick={(e) => filterClickHandle(e)}
              >
                {item.title}
              </a>
            );
          })}
        </div>
      </section>
      <section className="projects">
        {projects.map((item) => {
          if (currentFilter === "All" || currentFilter === item.filter) {
            return <Project key={item.id} {...item} />;
          }
        })}
      </section>
      <header className="footer" id="footer">
        <h3 className="footer-title">Feel free to contact me:</h3>
        <a className="contact-a" href="mailto:tomasian@yandex.ru">
          tomasian@yandex.ru
        </a>
        <a className="contact-a" href="skype:atamosin?userinfo">
          skype:atamosin
        </a>
        <a className="contact-a" href="https://www.facebook.com/atamosin">
          facebook.com/atamosin
        </a>
        <a className="contact-a" href="https://www.vk.com/tomasian">
          vk.com/tomasian
        </a>
      </header>
    </>
  );
}

export default App;
